<template>
  <section class="wa-full-height">

    <section class="has-background-black">

        <div class="columns is-gapless is-centered wa-underlined light-raised">
          <div class="column is-9">
              <div class="columns is-gapless">
                  <div class="column is-12" style="margin:auto;text-align:center">
                      <div style="padding:56.25% 0 0 0;position:relative;">
                        <!-- https://player.vimeo.com/video/440792147?autoplay=1&loop=0&autopause=0 -->
                        <!-- https://player.vimeo.com/video/475459367?autoplay=1&loop=0&autopause=0#t=11m0s -->
                        <iframe 
                            id="MyVimeoPlayer" 
                            ref="MyVimeoPlayer" 
                            src="https://player.vimeo.com/video/547981780?autoplay=1&loop=0&autopause=0#t=27m27s"
                            width="640" height="360" frameborder="0" 
                            allow="autoplay; fullscreen" 
                            allowfullscreen="true" 
                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                          >
                        </iframe>
                                                
                      </div>
                  </div>
                  <div v-if="false" class="column is-3">
                      <!-- <iframe src="https://vimeo.com/live-chat/521496969/0ef149251a" width="100%" height="100%" frameborder="0" style="height:100%;"></iframe>       -->
                  </div>
                  
                  
              </div>              
          </div>
        </div>
        
        <div class="column is-12" style="margin:auto;text-align:center">
          <a class="column is-size-1-mobile" href="/files/Powerpoint_PTSS_in_de_huisartsenpraktijk_definitief.pdf" target='_blank' style="margin:auto;text-align:center">
            <button class="documentdownloadBtn autoWordBreak button is-primary is-medium mb1rem ">Klik hier voor de presentatie</button>                         
          </a> 
        </div> 
              
        <div v-if="false" class="column is-12" style="margin:auto;text-align:center">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSerYjtkRaQhjWT5jcK8nYUhWNpDiEDF43sXw2qw680vPBmQfw/viewform?embedded=true" 
              width="100%" height="4000" frameborder="0" marginheight="0" marginwidth="0">
              Loading…
            </iframe>
        </div>       
    </section>

    <!-- <Footer></Footer> -->
  </section>
</template>

<script>

export default {
  name: "Home",
  
  components: {
    
  },
    
  data() {
    return {
      
    }
  },
  
  mounted(){
    // console.info("mounted:...");
  },
};
</script>

<style scoped>
</style>