import Vue from "vue";
import App from "@/App.vue";

import router from "@/router/router";
import store from "@/store";

import { Navbar, Button, Field, Input, Icon, Loading, Checkbox, Select } from "buefy";
Vue.use(Navbar);
Vue.use(Button);
Vue.use(Field);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(Checkbox);
Vue.use(Select);
// import Buefy from "buefy";
// Vue.use(Buefy);
require("@/assets/css/main.scss");


import web_lang from '@/lang/web_language'
Vue.prototype.$wl = web_lang;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
