<template>
  <div id="app" >
   <section class="hero">
      <!-- Vragen: -->
      <div id="vragenoverzicht" ref="vragenoverzicht" class="columns is-centered is-marginless">
          <div
            class="column is-8-desktop is-8-tablet is-10-mobile is-offset-1-mobile"
            style="padding-top:3rem; padding-bottom:3rem;"
          >
            <h1 class="underline">Veelgestelde vragen</h1>
            <span>
              Hier vindt u de antwoorden op veelgestelde vragen met betrekking tot de webinars en onze website. Staat uw vraag er niet tussen? Neem dan contact met ons op via het contactformulier. MedischeScholing.nl streeft ernaar om binnen 48 uur te reageren op uw bericht.
            </span>
            <br>
            
            
            
            <div class="columns is-multiline" style="padding-top:15px;padding-bottom:15px;">
              <div class="column is-12 wa-card bcFFFFFF mb3rem" >
                  <b-field label="Zoeken">
                      <b-input placeholder="Zoeken" v-model="PageSearchText" rounded @input="PageSearch"
                            icon-right="close-circle"
                            icon-right-clickable
                            @icon-right-click="clearSearch">
                            </b-input>
                  </b-field>
                  <span style="margin-right:10px">Kies:</span>
                  <span class="tag is-rounded is-green cursor-pointer" style="margin-right:10px" @click="SetSearch('beeld')">beeld</span>
                  <span class="tag is-rounded is-green cursor-pointer" style="margin-right:10px" @click="SetSearch('geluid')">geluid</span>
                  <span class="tag is-rounded is-green cursor-pointer" style="margin-right:10px" @click="SetSearch('wachtwoord')">wachtwoord</span>
                  <span class="tag is-rounded is-green cursor-pointer" style="margin-right:10px" @click="SetSearch('BIG')">BIG</span>
                  <span class="tag is-rounded is-green cursor-pointer" style="margin-right:10px" @click="SetSearch('registratienummer')">registratienummer</span>
                  <span class="tag is-rounded is-green cursor-pointer" style="margin-right:10px" @click="SetSearch('KABIZ')">KABIZ</span>
                  <span class="tag is-rounded is-green cursor-pointer" style="margin-right:10px" @click="SetSearch('aanmelden')">aanmelden</span>
                  <span class="tag is-rounded is-green cursor-pointer" style="margin-right:10px" @click="SetSearch('accreditatie')">accreditatie</span>
                  <span class="tag is-rounded is-green cursor-pointer" style="margin-right:10px" @click="SetSearch('bewijs van deelname')">bewijs van deelname</span>
                  <span class="tag is-rounded is-green cursor-pointer" style="margin-right:10px" @click="SetSearch('on demand')">on demand</span>
              </div>
            </div>
            
            
            
              <div class="filterblock">             
                <strong>Ik heb geen geluid, wat gaat er mis?</strong>
                <div >
                  Klik op de video van het webinar om het geluid aan te zetten. Zet rechtsonder de video de balk met geluid aan. Zorg ervoor dat het geluid van uw apparaat aan staat. 
                  Herlaad eventueel de pagina. 
                </div>
                <br>
              </div>
              
              <div class="filterblock">
                <strong>Ik heb geen beeld, wat gaat er mis?</strong>
                <div>
                  Herlaad de pagina en controleer uw internetverbinding. Wissel eventueel van browser, wij ondersteunen Internet Explorer niet. 
                  Kijkt u op een beveiligd zorgnetwerk? Wissel dan van WiFi of wissel van elektronisch apparaat. 
                </div>
                <br>
              </div>

              <div class="filterblock">
                <strong>Ik kan de accreditatievragen bij de live-uitzending niet zien.</strong>
                <div>
                  Herlaad de pagina en scroll naar beneden. De vragen worden onder het webinar getoond. U dient 70% goed te hebben voor een voldoende. U kunt eenmalig herkansen. 
                </div>
                <br>
              </div>
              
              <div class="filterblock">
                <strong>Ik kijk on demand, maar kan de vragen niet vinden.</strong>
                <div>
                  In overeenstemming met de KNMG-richtlijnen worden de vragen gedurende het webinar getoond. U herkent dit aan de banner die bovenaan in beeld komt, scroll wanneer deze banner in beeld komt naar beneden om de vraag/vragen te beantwoorden. Verstuur aan het einde uw antwoorden middels de knop ‘verstuur’. 
                </div>
                <br>
              </div>

              <div class="filterblock">
                <strong>Voor welke groepen is het webinar van 13 januari 2021 geaccrediteerd?</strong>
                <div>
                  Voor de ABAN (artsen), KABIZ (doktersassistenten), NVvPO (praktijkondersteuners), NAPA (physician assistants), V&VN VS (verpleegkundigen en verpleegkundig specialisten), KNMP (openbaar apotheker (AO) en ziekenhuisapotheker (ZA)). 
                  Voor alle overige groepen is er een certificaat van deelname te downloaden dat u zelf op kunt voeren in uw register. 
                </div>   
                <br><br>       
              </div>  
              
            
            
            
            
            <div class="filterblock">
              <span>
                <strong>Algemeen bericht:</strong> Voor accreditatie van de gevolgde webinars geldt het volgende;<br>
                <div class="divUnderline">Webinar 18 maart 2020:</div> ABAN-accreditatie is reeds bijgeschreven. Neem contact met ons op als dit niet het geval is.
                <br>
                <div class="divUnderline">Webinars 26 maart, 1 april, 8 april, 22 april, 6 mei, 3 juni, 30 september, 4 november 2020:</div>Accreditatie is reeds verwerkt en bijgeschreven. 
                <br>
                <div class="divUnderline">Webinar 13 januari 2021:</div> Accreditatie is reeds verwerkt en bijgeschreven.
                <br>                
                <div class="divUnderline">Webinar 17 februari 2021:</div> De accreditatievragen staan open t/m 1 maart 2021 18.00 uur. Vul de vragen vóór de sluitingsdatum in. Accreditatie wordt verwerkt in de maand maart 2021.
                <br>                
              </span>            
              <br>
              
              <span>
                  Wel deelgenomen, maar geen accreditatie? Neem contact met ons op. Doe dit onder vermelding van uw BIG-nummer/registratienummer en van welke webinars u de accreditatie mist. 
              </span>
              <br>
              
              <span>
                  Als u de vragen nog niet heeft beantwoord, doe dit dan vóór de sluitingsdatum. Na de sluitingsdatum wordt de accreditatie pas verwerkt in het accreditatiesysteem. Het is niet mogelijk de vragen na sluitingsdatum nog te beantwoorden. 
              </span>
              <br><br>
            </div>
            
            
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>DEELNEMEN AAN EEN WEBINAR</strong></span>            
              <span>
                <strong>Waar kan ik de webinars vinden waarvoor ik mij heb aangemeld?</strong>
                <div>
                  Ga naar <a class="divUnderline" href="https://webinar.medischescholing.nl" target="_blank" rel="noopener noreferrer">webinar.medischescholing.nl</a> en log in. Hier vindt u het overzicht van alle webinar waar u zich voor heeft aangemeld en welke u via uw dashboard kunt bekijken. 
                </div>
              </span>
              <br>
            </div>
            
            <div class="filterblock">
              <span>
                <strong>Hoe kan ik live deelnemen aan een webinar?</strong>
                <div>
                  Ga naar <a class="divUnderline" href="https://webinar.medischescholing.nl" target="_blank" rel="noopener noreferrer">webinar.medischescholing.nl</a> en log in. Selecteer hier het webinar waar u live aan wilt deelnemen middels de knop ‘ga naar de uitzending’ en bekijk vanaf die pagina het webinar.
                </div>
              </span>
              <br>
            </div>
            
            <div class="filterblock">
              <span>
                <strong>Ik kan de accreditatievragen bij de live-uitzending niet zien.</strong>
                <div>
                  Herlaad de pagina en scroll naar beneden. De vragen worden onder het webinar getoond. U dient 70% goed te hebben voor een voldoende. U kunt eenmalig herkansen. 
                </div>
              </span>
              <br>
            </div>
            
            
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>REGISTRATIE (BIG-NUMMER/ REGISTRATIENUMMER)</strong></span>
              <span>
                <strong>Het formulier accepteert mijn BIG-nummer niet. Wat nu?</strong>
                <div>
                  Het BIG-nummer is een 11-cijferige code. Controleer of uw BIG-nummer juist is. Voeg (een) voorloopnul(llen) toe indien uw BIG-nummer uit minder dan 11 cijfers bestaat. Elk BIG-nummer kan slechts eenmalig aan een profiel gekoppeld worden. Indien u reeds een profiel heeft, en u een nieuw profiel wilt aanmaken, neem dan contact met ons op via het contactformulier. 
                </div>
              </span>
              <br>
            </div>
            
            <div class="filterblock">
              <span>
                <strong>Wat is een kwaliteitsregistratienummer?</strong>
                <div>
                  Het kwaliteitsregistratienummer is voor zorgverleners die niet staan ingeschreven in het BIG-register. Met dit nummer heeft u zich geregistreerd in het kwaliteitsregister van uw beroepsvereniging/specialisme. Zonder dit nummer kunnen wij geen accreditatie bijschrijven en/of verwerken. 
                </div>
              </span>
              <br>
            </div>
            
            <div class="filterblock">
              <span>
                <strong>Mijn beroep staat niet in de lijst. Wat nu?</strong>
                <div>
                  Indien een overkoepelend beroep wel in de lijst staat, kies dan dit beroep uit de lijst. Bijvoorbeeld: ‘arts’, in plaats van ‘huisarts’. Ontbreekt uw beroep alsnog in de lijst, neem contact op via het contactformulier, dan zullen wij de ontbrekende beroepen toevoegen aan de lijst.
                </div>
              </span>
              <br>
            </div>
            
            
            
            
            
            
            
            
            
            
            
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>AANMELDEN EN ON DEMAND TERUGKIJKEN</strong></span>            
              <strong>Hoe kan ik me aanmelden voor de webinars?</strong>
              <ul>
                <li>1. Maak een account aan op MedischeScholing.nl. Heeft u al een account? Sla deze stap dan over. </li>
                <li>2. Ga naar <a class="underline" href="https://webinar.medischescholing.nl" target="_blank" rel="noopener noreferrer">webinar.medischescholing.nl</a> en log in met uw account.</li>
                <li>3. Vul eenmalig uw BIG-nummer of registratienummer in als de website daar om vraagt. Krijgt u deze vraag niet? Dan staat uw BIG-nummer of registratienummer al in uw profiel. De website vraagt slechts eenmalig om uw BIG-nummer of registratienummer.</li>
                <li>4. Schrijf u in voor het eerstvolgende webinar door op ‘aanmelden’ te klikken.</li>
                <li>5. Ga voor aanvang van het webinar naar <a class="underline" href="https://webinar.medischescholing.nl" target="_blank" rel="noopener noreferrer">webinar.medischescholing.nl</a> om live deel te nemen. </li>      
              </ul>
              <br>
            </div>







            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Waar kan ik de webinars on demand terugkijken?</strong></span>
              <span>
                <div>
                  Alle reeds uitgezonden webinars zijn on demand terug te kijken. Ga hiervoor naar https://webinar.medischescholing.nl. Log in en klik op 'Ga naar webinar'. Na een live-uitzending zal het webinar binnen twee werkdagen beschikbaar komen voor on demand streaming. De diapresentatie zal ook beschikbaar worden gesteld via de on demand pagina. 
                </div>
              </span>
              <br>
            </div>
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Ik ben zorgverlener, maar ik heb geen (geldig) BIG-nummer, hoe kan ik de webinars bekijken?</strong></span>
              <span>
                <div>
                  Indien u een (kwaliteits)registratienummer hebt via KABIZ/NVvPO, kunt u daarmee inloggen. 
                </div>
                <div>
                  Hiervoor klikt u in het inlogscherm op https://webinar.medischescholing.nl op ‘inloggen zonder BIG-nummer’. In het scherm dat u dan te zien krijgt, kunt u uw beroepsgroep selecteren uit de lijst en uw (kwaliteits)registratienummer invoeren.
                </div>
                <div>
                  Toegang tot de webinars voor zorgverleners zonder (geldig) registratienummer, zorgverleners uit overige beroepsgroepen, zorgverleners werkzaam in het buitenland en studenten verloopt via een gast-inlog. Mail voor deelname naar info@medischescholing.nl onder vermelding van uw naam en functie.
                </div>
              </span>
              <br>
            </div>
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Ik ben POH’er, moet ik mijn NVvPO-lidnummer of POH-registratienummer invullen bij aanmelding?</strong></span>
              <span>
                <div>
                  Als POH’er dient u uw POH-registratienummer in te vullen. Met enkel uw NVvPO-lidnummer kunnen wij uw accreditatie niet verwerken. 
                </div>
              </span>
              <br>
            </div>
            
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Het lukt mij niet om de webinars on demand te streamen? Wat gaat er mis?</strong></span>
              <span>
                <div>
                  Indien u de knop ‘bekijk de video’ niet ziet verschijnen op <a class="underline" href="https://webinar.medischescholing.nl" target="_blank" rel="noopener noreferrer">webinar.medischescholing.nl</a>, onderneem dan de volgende stappen:
                </div>
                <div>
                  <ul>
                    <li>1. Controleer uw internetverbinding en herlaad de pagina. Werkt dit niet? Volg dan stap 2 t/m 4.  </li>
                    <li>2. Log uit en sluit uw browser af.</li>
                    <li>3. Start uw browser opnieuw op, ga naar https://webinar.medischescholing.nl en log opnieuw in.</li>
                    <li>4. Klik op ‘bekijk de video’ voor on demand streamen.</li>
                  </ul>
                </div>
                <div>
                  Probeer eventueel een andere browser en/of elektronisch apparaat. Neem contact met ons op via het contactformulier als het na verschillende pogingen nog steeds niet lukt.
                </div>              
              </span>
              <br>
            </div>
            




            <div class="filterblock">
              <h2 class="underline" style="margin-top:20px;">ACCREDITATIE EN/OF BEWIJS VAN DEELNAME</h2>
            
              <span class="mt3rem mb1rem"><strong>Wat voor accreditatie kan ik voor de webinars ontvangen?</strong></span>
              <span>
                <div>
                  Accreditatie voor het webinar van 18 maart: 1 punt via ABAN. 
                  Accreditatie voor de webinars van 26 maart en 1 april: 1,5 punt via ABAN, KABIZ, NVvPO, NAPA en V&VN VS. 
                  Accreditatie voor de webinars van 8 april, 22 april, 6 mei, 3 juni, 30 september, 4 november 2020: 1 punt via ABAN, KABIZ, NVvPO, NAPA en V&VN VS. 
                  Accreditatie voor het webinar van 13 januari, 17 februari 2021: 1 punt via ABAN, KABIZ, NVvPO, NAPA, V&VN (VS) en KNMP. 
                </div>
                <div>
                  Voorafgaand aan elk webinar wordt gecommuniceerd hoeveel accreditatiepunten er voor het webinar staan. Het aantal toegekende punten staat vast en zal niet worden gewijzigd, ook als de live uitzending langer duurt. 
                </div>
              </span>
              <br>
            </div>
            
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Wanneer wordt mijn accreditatie voor de webinars uitgezonden van 18 maart, 26 maart, 1 april, 8 april, 22 april, 6 mei, 3 juni, 30 september en 4 november 2020 en 13 januari 2021 bijgeschreven?</strong></span>
              <span>
                <div>
                  De accreditatie is reeds bijgeschreven. Neem contact met ons op indien dit niet het geval is. Doe dit onder vermelding van uw BIG- of registratienummer. 
                </div>
              </span>
              <br>
            </div>
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Hoe kan ik een bewijs van deelname krijgen?</strong></span>
              <span>
                <div>
                  Indien en nadat u de accreditatie-vragen hebt beantwoord, kunt u een bewijs van deelname downloaden. Deze knop wordt alleen zichtbaar indien u de vragen binnen de daarvoor gestelde termijn heeft beantwoord.  
                </div>
                <div>
                  <ul>
                    <li>1. Ga naar https://webinar.medischescholing.nl en log in. </li>
                    <li>2. Klik op ‘ga naar webinar’ bij het webinar naar keuze.</li>
                    <li>3. Beantwoord de vragen indien u dit nog niet gedaan heeft. Klik na invullen op verzenden. Sla deze stap over als u de vragen reeds heeft beantwoord.</li>
                    <li>4. Klik op ‘bewijs van deelname’. Controleer uw gegevens.</li>
                    <li>5. Gegevens niet juist? Wijzig deze onder ‘profiel’. Ga dan terug naar stap 4.</li>
                    <li>6. Klik op ‘afdrukken’ > ‘opslaan als pdf’.</li>
                    <li>7. Herhaal stap 2-6 voor de andere webinars waar u een bewijs van wilt hebben.</li>
                  </ul>
                </div>
                <div>
                  Dit bewijs kan gebruikt worden voor het verkrijgen van accreditatie die buiten de ABAN, KABIZ, NVvPO, NAPA en V&VN VS valt. 
                </div>
              </span>
              <br>
            </div>
            


            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Ik zie de knop ‘Bewijs van deelname’ niet. Wat gaat er fout?</strong></span>
              <span>
                <div>
                  U ziet de knop ‘Bewijs van deelname’ alleen indien de accreditatievragen zijn ingevuld vóór de sluitingsdatum. Indien u de vragen niet heeft ingevuld, ziet u de knop niet en kunt u geen certificaat ontvangen. Het is niet mogelijk om de vragen na sluitingsdatum nog in te vullen. 
                </div>
              </span>
              <br>
            </div>
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Er wordt mij gevraagd om mijn gegevens in mijn profiel te wijzigen, maar dit heb ik al eens gedaan. Wat nu?</strong></span>
              <span>
                <div>
                  De website vraagt u regelmatig om uw gegevens te controleren, zo weten wij zeker dat de gegevens die wij van u hebben de juiste zijn. Dit is van belang voor het bijschrijven van de accreditatie. U kunt dit verzoek negeren indien u zeker weet dat uw gegevens correct in uw profiel staan.
                </div>
              </span>              
              <br>
            </div>
            
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Op de website staan de webinars in de periode van 18 maart - 4 november allemaal op 0 punten. Ik heb wel deelgenomen en de vragen voor sluitingsdatum ingevuld, krijg ik nu wel mijn punten?</strong></span>
              <span>
                <div>
                  Ja. De punten worden bijgeschreven indien u aan de volgende drie punten voldoet: u valt in een van de accreditatie-groepen, u heeft de vragen beantwoord en ingestuurd, en uw BIG- of registratienummer is bij ons bekend. De webinars staan enkel op 0 punten omdat de mogelijkheid tot het verkrijgen van accreditatie voor deze webinars inmiddels is verlopen. Deze wijziging in weergave heeft geen gevolgen voor uw accreditatieaanvraag en bijschrijving.
                </div>
              </span>              
              <br>
            </div>
            
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Ik behoor tot de KABIZ-accreditatiegroep, maar ik heb geen accreditatie ontvangen. Hoe kan dit?</strong></span>
              <span>
                <div>
                  Om vanuit de KABIZ accreditatie te kunnen ontvangen in GAIA, dient u onderdeel te zijn van PE-online. Dit geldt enkel voor de praktijkmanagers huisartsenpraktijk (NVvPM) en doktersassistenten (CADD van de NVDA). Als u bijvoorbeeld apothekersassistent bent wordt accreditatie niet in PE-online verwerkt. U dient dan zelf een bewijs van deelname op te voeren bij uw beroepsvereniging om accreditatie te ontvangen. Een bewijs van deelname kunt u downloaden op de pagina van elke webinar waarvoor u de accreditatievragen hebt ingevuld en verstuurd.
                </div>
              </span>              
              <br>
            </div>
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Ik ben arts, val ik dan onder de ABAN accreditatie?</strong></span>
              <span>
                <div>
                  Als arts valt u inderdaad onder de ABAN-accreditatie. Alle artsen, ongeacht het specialisme, kunnen accreditatie verkrijgen binnen de ABAN.
                </div>
              </span>              
              <br>
            </div>
            
            
            
            <div class="filterblock">
              <h2 class="underline" style="margin-top:20px;">VRAGEN INSTUREN</h2>            
              <span class="mt3rem mb1rem"><strong>Hoe kan ik vragen insturen voor het webinar? </strong></span>
              <span>
                <div>
                  Ga naar corona.medischescholing.nl en meld u aan voor het webinar. Hierna zal er een knop verschijnen met ‘Stuur vragen in’. Voor het insturen van een vraag kunt u deze koppelen aan een reeds bestaande categorie of zelf een nieuwe categorie maken. 
                </div>
              </span>
              <br>
            </div>
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Hoe werkt het ‘upvoten’ van vragen?</strong></span>
              <span>
                <div>
                  U kunt vragen liken door middel van het duim-icoontje. Als u hierop klikt heeft u de vraag geliket. Hoe meer likes een vraag heeft, des te hoger deze komt te staan, en hoe meer zwaarte deze krijgt in een webinar.
                </div>
              </span>              
              <br>
            </div>
            
            
            <div class="filterblock">
              <h2 class="underline" style="margin-top:20px;">TECHNISCHE PROBLEMEN</h2>            
              <span class="mt3rem mb1rem"><strong>Ik kan niet inloggen, wat gaat er mis?</strong></span>
              <span>
                <div>
                  Controleer uw internetverbinding, en herlaad de pagina terwijl u de shiftknop indrukt. Probeer in te loggen op onze hoofdwebsite, MedischeScholing.nl. Indien u uw wachtwoord vergeten bent, klik op ‘wachtwoord vergeten’. Lukt het inloggen herhaaldelijk niet? Neem dan contact met ons op in het contactformulier. 
                </div>
              </span>
              <br>
            </div>
            
            
            
            <div class="filterblock">            
              <span class="mt3rem mb1rem"><strong>Ik ben mijn wachtwoord vergeten.</strong></span>
              <span>
                <div>
                Klik bij inloggen op ‘wachtwoord vergeten?’. Volg de stappen om uw wachtwoord opnieuw in te stellen.
                </div>
              </span>
              <br>
            </div>
            
            
            <div class="filterblock">
            <span class="mt3rem mb1rem"><strong>Ik ontvang geen e-mail voor de wachtwoordreset. Wat gaat er mis?</strong></span>
              <span>
                <div>
                Controleer of de e-mail in uw spamfolder terecht is gekomen. Mocht u de mail niet ontvangen hebben, vraag dan opnieuw een nieuw wachtwoord aan. Probeer het eventueel op onze andere websites: corona.medischescholing.nl of medischescholing.nl. Neem contact met ons op als u na herhaling nog steeds alsnog geen wachtwoordreset kunt uitvoeren.
                </div>
                <div>
                Let op: gebruik geen Internet Explorer. Browsers die wel worden ondersteund zijn: Google Chrome, Microsoft Edge, Firefox en Safari.
                </div>
              </span>
              <br>
            </div>
            
            
            
            <div class="filterblock">            
              <span class="mt3rem mb1rem"><strong>Ik krijg een 402- of 404-error te zien.</strong></span>
              <span>
                <div>
                Herlaad de pagina terwijl u de shiftknop indrukt, u heeft te lang geen actie ondernomen. Lukt dit niet, probeer dan een andere browser of ander apparaat. Blijft u problemen houden? Neem dan contact met ons op via het contactformulier.
                </div>
              </span>
              <br>
            </div>
            
            
            <div class="filterblock">
              <span class="mt3rem mb1rem"><strong>Ik krijg een melding dat mijn browser niet wordt ondersteund.</strong></span>
              <span>
                <div>
                MedischeScholing.nl ondersteunt Internet Explorer niet. U dient gebruik te maken van Google Chrome, Safari, Microsoft Edge of Firefox. Wissel van browser of gebruik een ander elektronisch apparaat. 
                </div>
              </span>
              <br>
            </div>
            
            
            
            <div class="filterblock">
              <h2 class="underline" style="margin-top:20px;">OVERIG</h2>            
              <span class="mt3rem mb1rem"><strong>Ik wil graag feedback geven, waar kan ik dit achterlaten?</strong></span>
              <span>
                <div>
                  U kunt uw feedback achterlaten in het contactformulier. Wij zullen contact met u opnemen als we aanvullende vragen hebben. 
                </div>
              </span>
            </div>



          </div>
        </div>
    </section>
    
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <div class="columns is-gapless">
            <div class="column is-narrow">
              <div id="contactformulier" class="has-text-grey is-size-3 has-text-weight-light wa-underlined-title" >Contactformulier</div>
            </div>
          </div>
        </div>
      </div>
          
      <div class="columns is-multiline" style="padding-bottom:100px;">
        <div class="column is-8 is-offset-2 wa-card bcFFFFFF mb3rem" >

            <b-field label="Naam">
                <b-input placeholder="Naam" v-model="frmdata.name" rounded></b-input>
            </b-field>

            <b-field label="Email">
                <b-input placeholder="Email" v-model="frmdata.email" type="email" rounded ></b-input>
            </b-field>

            <b-field label="Bericht">
              <b-input type="textarea" maxlength="2000" v-model="frmdata.message" rounded expanded placeholder="Typ een bericht"></b-input>
            </b-field>

            <div class="columns is-centered ta-center">
              <div class="column is-narrow">
                  <button
                      class="button has-text-centered is-rounded is-primary is-medium" style="margin-top:20px;"
                      slot="trigger"
                      role="button">
                      <span @click="SubmitContactMsg()">Verstuur bericht</span>
                  </button>
              </div>
            </div>
        </div>
      </div>


  </div>
</template>

<script>
// import Title from "@/components/public/general/title.vue";
// import Card from "@/components/public/general/card.vue";
// import ButtonsForm from "@/components/public/general/buttonform.vue";
import { mapState } from "vuex";

export default {
  name: "contactus",

  components: {
    // Title,
    // Card,
    // ButtonsForm
  },

  data() {
    return {
      PageSearchText : '',
      filterblocks : [],
      frmdata: {
        name: "",
        email: "",
        message: ""
      },
      
    };
  },

  computed: {
    ...mapState("authentication", [
      "authentication/loggedInState",
      "loggedInState"
    ])
  },
  watch: {
    loggedInState(loggedInState) {
      // console.info("LoggedInState Changed:",  loggedInState);
      this.UpdateFrmData(loggedInState);
    }
  },

  async mounted() {
    this.$store.dispatch("authentication/updateLoginState", null);
    
    const vragenoverzicht = this.$refs.vragenoverzicht;
    // console.info("vragenoverzicht:", vragenoverzicht);
    this.filterblocks = vragenoverzicht.getElementsByClassName("filterblock");    
    // console.info("this.filterblocks:", this.filterblocks);
  },

  methods: {
    UpdateFrmData(loggedInState) {
      if (loggedInState.name !== null) {
        this.frmdata.name = loggedInState.name.toString();
      }
      if (loggedInState.email !== null) {
        this.frmdata.email = loggedInState.email.toString();
      }
    },

    validateEmail() {
      if (this.frmdata.email.trim() === "") {
        this.$store.commit("dialog/setDialog", {
          DialogType: "alert",
          title: this.$wl("general_error_msg"),
          message: this.$wl("contact_us_error_email")
        });
        return false;
      }
      return true;
    },

    validateName() {
      if (this.frmdata.name.trim() === "") {
        this.$store.commit("dialog/setDialog", {
          DialogType: "alert",
          title: this.$wl("general_error_msg"),
          message: this.$wl("contact_us_error_name")
        });
        return false;
      }
      return true;
    },

    validateMsg() {
      if (this.frmdata.message.trim() === "") {
        this.$store.commit("dialog/setDialog", {
          DialogType: "alert",
          title: this.$wl("general_error_msg"),
          message: this.$wl("contact_us_error_msg")
        });
        return false;
      }
      return true;
    },

    async SubmitContactMsg() {
      if (!this.validateName() || !this.validateEmail() || !this.validateMsg())
        return;

      let contactRtn = await this.$store.dispatch(
        "contactus/sendMessage",
        this.frmdata
      );
      // console.info("contactRtn:", contactRtn);
      if (contactRtn !== false) {
        this.$store.commit("dialog/setDialog", {
          DialogType: "info",
          title: this.$wl("general_msg_ok"),
          message: this.$wl("contact_us_msg_received")
        });
        this.frmdata.message = "";
        this.$router.push({ path: "/" });
      }
    },
    
    
    clearSearch(){
      this.SetSearch('');
    },
    SetSearch(newSearch){
      console.info("SetSearch:", newSearch);
      this.PageSearchText = newSearch;
      this.PageSearch();
    },
    async PageSearch() {
      // console.info("Searching for:" , this.PageSearchText);
      // const search = "GELUID"
      
      // const vragenoverzicht = this.$refs.vragenoverzicht;
      // console.info("vragenoverzicht:", vragenoverzicht);
      // const filterblocks = this.$refs.vragenoverzicht.getElementsByClassName("filterblock");    
      // console.info("this.filterblocks:", this.filterblocks);
      const filterblocks = this.filterblocks;
      
      
      // let SearchResult = [];
      for(let key in filterblocks) {
        
        try {
          let itemblock = filterblocks[key];
          console.info("typeof(itemblock):", typeof(itemblock));
          if (typeof(itemblock) !== "object") continue;
          
          itemblock.className = "filterblock";
          
          if (itemblock.innerHTML.toLowerCase().indexOf(this.PageSearchText.toLowerCase()) > -1) {
            // SearchResult.push(itemblock);
            itemblock.className = "filterblock isOK";
          } else {
            itemblock.className = "filterblock HideDiv";
          }
        } catch (err){
          console.info("error:", err);
        }
      }
      
      
      // console.info("this.filterblocks:", filterblocks);
    }
  }
};
</script>

<style lang="css">
html {
  height: 100%;
}

.HideDiv{
 display:none !important; 
 /* background-color: red;  */
}
.isOK{
 /* background-color: green;  */
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f4f6fb;
  font-family: 'Roboto';
}

.divUnderline{
  display:inline;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-color: #f37c82;
}



@font-face {
    font-family: "Roboto";
    src: url("/fonts/Roboto-Regular.eot");
    src: url("/fonts/Roboto-Regular.eot?#iefix") format('embedded-opentype'),
         url("/fonts/Roboto-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Roboto italic */

@font-face {
    font-family: "Roboto";
    src: url('/fonts/Roboto-Italic.eot');
    src: url('/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

/* Roboto bold */

@font-face {
    font-family: "Roboto";
    src: url('/fonts/Roboto-Bold.eot');
    src: url('/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

#app {
  margin: 0;
  padding: 0;
}
</style>
