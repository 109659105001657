import Contact            from "@/views/public/general/contact";
import Privacy            from "@/views/public/general/privacy";
import TermsAndConditions from "@/views/public/general/termsandconditions";
import Disclaimer         from "@/views/public/general/disclaimer";
import Home               from "@/views/public/home";


 const Routes_Public = [{
                              path: "/",
                              name: "home",
                              component: Home,
                              meta: { publicNav: true }
                            },{
                              path: "/48/SXCU4966",
                              name: "home",
                              component: Home,
                              meta: { publicNav: true }
                            },{
                              path: "/contact",
                              name: "Contact",
                              component: Contact,
                              props: true,
                              meta: { publicNav: true }
                            },{
                              path: "/privacy",
                              name: "Privacy",
                              component: Privacy,
                              props: true,
                              meta: { publicNav: true }
                            },{
                              path: "/termsandconditions",
                              name: "TermsAndConditions",
                              component: TermsAndConditions,
                              props: true,
                              meta: { publicNav: true }
                            },{
                              path: "/disclaimer",
                              name: "Disclaimer",
                              component: Disclaimer,
                              props: true,
                              meta: { publicNav: true }
                              
                            },{
                              path: "/home",
                              name: "Home",
                              component: Home,
                              props: true,
                              meta: { publicNav: true }
                            }
];

export default Routes_Public;